export default [
  // {
  //   header: 'Setting',
  //   icon: 'SettingsIcon',
  //   children: [
  //     {
  //       title: 'Power BI',
  //       route: 'misc-not-authorized',
  //       // icon: 'SettingsIcon',
  //       // acl: {
  //       action: 'read',
  //       resource: 'ACL',
  //       // },
  //     },
  //     // {
  //     //   title: 'Documentation',
  //     //   href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation',
  //     //   icon: 'FileTextIcon',
  //     // },
  //   ],
  // },
]
